<script lang="ts" setup>
import type {
  VideoJsPlayer as IPixellotPlayer,
  ITag as IPixellotTag,
  ITagGroup as IPixellotTagGroup,
  IPlayerControlLink,
} from "@pixellot/web-sdk";
import type { IPlayerState, IPixellotPlayerSource, IPixellotPlayerVideoClipOptions } from "~/modules/pixellot-sdk";
import type { IAnalyticsOptions } from "@pixellot/web-sdk";
import type { IBasicVideo, IEvent, ITag } from "~/types";
import { PxlVideoPlayer } from "#components";
import { useStorage } from "@vueuse/core";
import { vOnClickOutside } from "@vueuse/components";
import getFloatingPosition from "~/helpers/get-floating-position";
import { VIDEO_TYPE } from "~/constants";

export interface IPlayerData {
  video: HTMLVideoElement;
  player: IPixellotPlayer;
  state: IPlayerState | null;
}

const props = defineProps<{
  tags?: IPixellotTag[];
  video?: null | IBasicVideo;
  videoClip?: IPixellotPlayerVideoClipOptions;
  playerClass?: string | Record<string, boolean>;
  seekButtons?: {
    isActive: boolean;
    onClickNext: () => void;
    onClickPrevious: () => void;
    isPreviousDisabled: boolean;
    isNextDisabled: boolean;
  };
  controlLink?: IPlayerControlLink;
  errorMessage?: string;

  upNextVideo?: false | null | IBasicVideo;
  upNextVideoShown?: boolean;
  upNextVideoCategory?: string;
}>();
const emit = defineEmits<{
  (event: "mounted", data: IPlayerData): void;
  (event: "unmounted"): void;
  (event: "tagClick", data: { event: PointerEvent | MouseEvent; data: IPixellotTag | IPixellotTagGroup }): void;
  (event: "delete-tag", tag: ITag): void;
  (event: "edit-tag", tag: ITag): void;
  (event: "up-next-click", video: IBasicVideo): void;
}>();

const user = useUser();
const route = useRoute();
const runtimeConfig = useRuntimeConfig();
const autoplay = useSessionStorage("enable-pixellot-video-autoplay", true);
const defaultVolume = useStorage("cache-pixellot-video-volume", 1);
const isPanoControlsVisible = useStorage("enable-pixellot-controls-tool-visible", true);
const segment = useSegment();
const playerRef = ref<InstanceType<typeof PxlVideoPlayer> | null>(null);
const playerInstance = computed<IPixellotPlayer | null>(() => (playerRef.value ? playerRef.value.player : null));
const playerState = computed<IPlayerState | null>(() => (playerRef.value ? playerRef.value.state : null));
const selectedTagData = ref<IPixellotTag[] | ITag[] | null>(null);
const selectedTagSelector = ref<string | null>(null);
const hasError = ref(false);
const playerAnalytics = computed<IAnalyticsOptions | null>(() => {
  if (!runtimeConfig.public.youboraAccountCode || !props.video) return null;

  return {
    appProjectId: runtimeConfig.public.pixellotProjectId,
    accountCode: runtimeConfig.public.youboraAccountCode as "pixellot" | "pixellotdev",
    contentTitle: props.video.title,
    contentIsLive: playerState.value?.isLive,
    userID: user.value?.uid,
    userType: "viewer",
    videoMode: playerRef.value?.source.type,
    entityID: props.video.id,
    sportType: props.video.type === "event" ? (props.video as IEvent).sport_type : null,
    eventID: props.video.eventId,
  };
});
const playerSource = computed<IPixellotPlayerSource[]>(() => {
  if (!props.video) {
    return [];
  }

  const sources: IPixellotPlayerSource[] = [];

  props.video.urls.forEach((item) => {
    sources.push({
      url: item.url,
      type: item.type,
      thumbnails_url: item.thumbnails_url,
      clip: props.videoClip,
    });
  });

  return sources;
});
const hasAnyVideoSource = computed(() => playerSource.value.length > 0);

watch(
  () => playerRef.value?.player?.player_,
  (player: IPixellotPlayer | undefined) => {
    if (!player) return;

    player.player_.on("ratechange", () => {
      if (!player || !props.video) return;

      segment.track("Video Playback Rate Changed", {
        ...formatTrackVideo(props.video),
        ...{ rate: player.playbackRate() },
      });
    });

    player.player_.on("fullscreenchange", () => {
      if (!player || !props.video) return;

      segment.track(
        document.fullscreenElement ? "Video Fullscreen Opened" : "Video Fullscreen Closed",
        formatTrackVideo(props.video),
      );
    });

    player.player_.on("seeked", function () {
      if (!player || !props.video) return;

      segment.track("Video Seeked", {
        ...formatTrackVideo(props.video),
        ...{ time: player.currentTime() },
      });
    });

    player.player_.on("hls-qualitychange", function (e, { label }) {
      if (!player || !props.video) return;

      segment.track("Video Quality Changed", {
        ...formatTrackVideo(props.video),
        ...{ quality: label },
      });
    });
  },
);

watch(
  () => playerState.value?.paused,
  (isPaused) => {
    if (!props.video) {
      return;
    }

    if (isPaused) {
      segment.track("Video Paused", formatTrackVideo(props.video));
    } else {
      segment.track("Video Played", formatTrackVideo(props.video));
    }
  },
);

watch(
  () => playerRef.value?.source?.type,
  (activeSourceType) => {
    if (!props.video) {
      return;
    }

    segment.track("Opened Video Source", {
      ...formatTrackVideo(props.video),
      ...{ sourceType: activeSourceType },
    });
  },
);

watch(
  () => playerState.value?.muted,
  (isMuted) => {
    if (!props.video) {
      return;
    }

    if (isMuted) {
      segment.track("Video Audio Muted", formatTrackVideo(props.video));
    } else {
      segment.track("Video Audio Unmuted", formatTrackVideo(props.video));
    }
  },
);

watch(
  () => playerState.value?.volume,
  (volume) => {
    if (!props.video) {
      return;
    }

    if (volume) {
      segment.track("Video Volume Changed", {
        ...formatTrackVideo(props.video),
        volume,
      });
    }
  },
);

watch(
  () => playerState.value?.panoMode,
  (panoMode) => {
    if (!props.video) {
      return;
    }

    if (panoMode) {
      segment.track("Pano Mode Changed", {
        ...formatTrackVideo(props.video),
        panoMode,
      });
    }
  },
);

function onPlayerLoaded({ player, video, state }: IPlayerData) {
  player.on("volumechange", () => {
    defaultVolume.value = playerInstance.value?.volume();
  });

  player.on("error", () => (hasError.value = true));

  if (props.video) {
    player.one("playing", () => {
      if (props.video) {
        logVideoView(props.video.id, props.video.type, {
          accessToken: (route.query.accessToken as string) || undefined,
        });
      }
    });

    segment.track("Video Opened", {
      ...formatTrackVideo(props.video),
      ...{ autoplay: autoplay.value },
    });
  }

  emit("mounted", { player, video, state });
}

function onPlayerUnloaded() {
  emit("unmounted");

  if (props.video) {
    segment.track("Video Closed", formatTrackVideo(props.video));
  }
}

function cleanSelectedTagId() {
  const el = document.getElementById("selectedTag");

  if (el) el.id = "";
}

function onTagClick(data: { event: PointerEvent | MouseEvent; data: IPixellotTag | IPixellotTagGroup }) {
  emit("tagClick", data);
  const targetEl = event?.target as HTMLDivElement | null;

  if (!targetEl) return;

  const tagTargetEl: HTMLDivElement | null = targetEl.closest(".vjs-marker");

  if (!tagTargetEl) return;

  cleanSelectedTagId();
  tagTargetEl.id = "selectedTag";
  selectedTagData.value = (data.data as IPixellotTagGroup)?.tags ? (data.data as IPixellotTagGroup).tags : [data.data];
  selectedTagSelector.value = "#selectedTag";
}

function onTagEdit(tag: ITag) {
  if (playerInstance.value?.isFullscreen()) playerInstance.value?.exitFullscreen();
  onClickOutsideTagPopover();

  emit("edit-tag", tag);
}

function onTagDelete(tag: ITag) {
  if (playerInstance.value?.isFullscreen()) playerInstance.value?.exitFullscreen();
  onClickOutsideTagPopover();

  emit("delete-tag", tag);
}

function onClickOutsideTagPopover() {
  cleanSelectedTagId();
  selectedTagData.value = null;
  selectedTagSelector.value = null;
}

function getPopoverXPositionClass() {
  if (!selectedTagSelector.value) return;

  const tagTargetEl: HTMLElement | null = document.querySelector(selectedTagSelector.value);
  const playerEl: HTMLElement | null = (playerInstance.value?.el() as HTMLElement) || null;

  if (!tagTargetEl || !playerEl) return;

  return getFloatingPosition(tagTargetEl, playerEl, { position: "x" });
}

function getStartTimeOnSourceSwitchForEvent(params: {
  oldSrc: IPixellotPlayerSource;
  newSrc: IPixellotPlayerSource;
  playedTime: number;
}): Promise<number> {
  const options = {
    streamType: params.newSrc.type,
    timeInSeconds: params.playedTime,
    eventAbeId: props.video?.eventAbeId,
  };
  return getActualEventTimeForStream(props.video?.eventId!, options);
}

watchEffect(() => {
  if (props.errorMessage) hasError.value = true;
});

defineExpose({
  error: hasError,
  player: playerInstance,
  state: playerState,
});
</script>

<template>
  <VideoPlayerError
    v-if="hasError"
    :error-message="props.errorMessage"
  />
  <PxlVideoPlayer
    v-else-if="props.video && hasAnyVideoSource"
    id="pixellotPlayer"
    v-slot="{ player, source, state, setSrcType }"
    ref="playerRef"
    class="aspect-video"
    pano-controls-selector="#panoControlsPlaceholder"
    render-tag-groups
    :tags="props.tags"
    :class="props.playerClass"
    :analytics="playerAnalytics"
    :autoplay="autoplay"
    :default-volume="defaultVolume"
    :control-link="props.controlLink"
    :seek-buttons="props.seekButtons"
    :src="playerSource"
    :get-start-time-on-source-switch="props.video.type === VIDEO_TYPE.EVENT ? getStartTimeOnSourceSwitchForEvent : null"
    switch-type="selector"
    @mounted="onPlayerLoaded"
    @unmounted="onPlayerUnloaded"
    @tag-click="onTagClick"
  >
    <VideoPlayerTopBar
      :player="player"
      :source="source"
      :state="state"
      :set-src-type="setSrcType"
    />

    <Transition
      v-show="state.hasStarted && source.type === 'pano'"
      enter-from-class="opacity-0"
      leave-to-class="opacity-0"
      enter-active-class="transition duration-300"
      leave-active-class="transition duration-300"
    >
      <div
        v-show="(state.userActive || state.paused) && source.type === 'pano'"
        class="absolute bottom-[100px] left-3 flex gap-3 items-start"
        style="direction: ltr"
      >
        <div
          v-show="isPanoControlsVisible"
          id="panoControlsPlaceholder"
        />

        <UButton
          v-show="state.userActive || state.paused"
          class="max-md:hidden !bg-[#1A1C1E] hover:!bg-[#1A1C1EB8]"
          :icon="
            isPanoControlsVisible
              ? { name: 'chevron-left', class: 'w-3 h-3 text-white' }
              : { name: 'chevron-right', class: 'w-3 h-3 text-white' }
          "
          variant="dark"
          size="lg"
          @click="isPanoControlsVisible = !isPanoControlsVisible"
        />
      </div>
    </Transition>

    <Transition
      v-if="props.upNextVideo"
      enter-from-class="opacity-0"
      leave-to-class="opacity-0"
      enter-active-class="transition duration-300"
      leave-active-class="transition duration-300"
    >
      <VideoUpNext
        v-if="props.upNextVideo && props.upNextVideoShown"
        :video="props.upNextVideo"
        :category-name="props.upNextVideoCategory"
        @click="emit('up-next-click', props.upNextVideo)"
      />
    </Transition>

    <Teleport
      v-if="selectedTagSelector && selectedTagData?.length"
      :to="selectedTagSelector"
    >
      <div
        v-on-click-outside="onClickOutsideTagPopover"
        class="absolute bottom-6 z-[100] w-[188px]"
        :class="getPopoverXPositionClass()"
      >
        <VideoPlayerTagPopover
          :tags="selectedTagData as ITag[]"
          @edit-tag="onTagEdit"
          @delete-tag="onTagDelete"
        />
      </div>
    </Teleport>

    <slot
      :player="player"
      :source="source"
      :state="state"
      :set-src-type="setSrcType"
    />
  </PxlVideoPlayer>
  <VideoPlayerEmpty v-else />
</template>

<style lang="scss">
#selectedTag {
  background-color: var(--vjs-theme-pxl--primary);
}

// TODO: Move inside of SDK as a default styles
.pixellot-player .pxlt-navigation-area {
  padding: 0;
  background: none;
  border-radius: 4px;
  overflow: hidden;

  .pxlt-navigation-area-container {
    background: rgba(0, 0, 0, 0.4);
    box-shadow: none;
    border: none;
  }

  .pxlt-zoom-area {
    background: rgba(0, 0, 0, 0.4);
    box-shadow: none;
    border: none;
  }
}

.video-js button.vjs-control-link {
  display: flex;
  align-items: center;

  &::after {
    background: url(/assets/icons/link-external.svg) no-repeat center center;
    filter: brightness(100); // make it white
    border: none;
    transform: none;
    width: 0.75rem;
    height: 0.75rem;
    margin-left: 0.5rem;
    margin-top: 0;
  }
}
</style>
