import { defu } from "defu";

interface FloatingPositionConfig {
    position: "x" | "y" | "both";
    classes: {
        left: string;
        right: string;
        top: string;
        bottom: string;
    };
  }

const defaults: FloatingPositionConfig = {
    position: "both",
    classes: {
        left: "left-0",
        right: "right-0",
        top: "top-0",
        bottom: "bottom-0"
    }
};

export default function (
  targetElement: HTMLElement,
  containerElement: HTMLElement,
  options: Partial<FloatingPositionConfig>
) {
  const { top, left, width, height } = targetElement.getBoundingClientRect();
  const config = defu(defaults, options);

  const elementMidpointX = left + width / 2;
  const elementMidpointY = top + height / 2;
  const containerWidth = containerElement.clientWidth;
  const containerHeight = containerElement.clientHeight;

  // Calculate the horizontal and vertical positions of the container's midpoint
  const containerMidpointX = containerWidth / 2;
  const containerMidpointY = containerHeight / 2;

  let positionClass = "";
  
  // Compare the element's midpoint with the container's midpoint
  if (config.position === "x" || config.position === "both")
    positionClass += elementMidpointX < containerMidpointX ? config.classes.left + " " : config.classes.right + " ";
  if (config.position === "y" || config.position === "both")
    positionClass += elementMidpointY < containerMidpointY ? config.classes.top : config.classes.bottom;

  return positionClass;
}
