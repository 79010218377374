<script lang="ts" setup>
const { t } = useI18n();
const colorMode = useColorMode();
const isDark = computed(() => colorMode.value === "dark");
</script>

<template>
  <div
    class="flex flex-col gap-6 aspect-video justify-center items-center bg-neutral-light-100 dark:bg-neutral-dark-800 p-10"
  >
    <svg
      v-if="isDark"
      class="w-48 h-64"
      width="192"
      height="267"
      viewBox="0 0 192 267"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2543_6635)">
        <path
          d="M192 256.303C192 262.032 157.515 266.739 112.997 267.42C109.392 267.48 105.717 267.5 102 267.5C100.91 267.5 99.8342 267.5 98.7722 267.5C96.7601 267.5 94.762 267.48 92.7778 267.46C87.7895 267.4 82.9269 267.28 78.218 267.119C40.0578 265.817 12 261.471 12 256.323C12 250.134 52.2981 245.127 102 245.127C151.702 245.127 192 250.114 192 256.303Z"
          fill="#364157"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.1399 38.535L34.8969 45.9065L40.4841 58.5053L31.7992 62.2892L22.1241 40.483L29.1399 38.535Z"
          fill="#0F1624"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.9762 2.07603L29.1525 38.5391L22.5352 41.4208L6.35891 4.95774L12.9762 2.07603Z"
          fill="#2C374B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.1642 7.00821L15.8074 8.458L9.19018 11.3397L8.547 9.88991L15.1642 7.00821Z"
          fill="#0F1624"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.1833 44.1827L17.7675 53.3621L23.3688 65.9609L32.0679 62.1771L22.3928 40.3708L16.1833 44.1827Z"
          fill="#0F1624"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.61724 4.84189L22.7936 41.305L16.1763 44.1867L-5.36833e-06 7.7236L6.61724 4.84189Z"
          fill="#2C374B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.80563 9.774L9.44882 11.2238L2.83157 14.1055L2.18839 12.6557L8.80563 9.774Z"
          fill="#0F1624"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.5771 63.3403C37.2449 61.2942 40.767 59.136 40.4841 58.5053C40.2012 57.8607 36.2124 58.9818 31.5446 61.0139L32.5771 63.3403Z"
          fill="#1C2535"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.5446 61.0139C31.5021 61.0279 31.4597 61.0559 31.4173 61.0699C26.6929 63.13 23.0859 65.3163 23.3688 65.9609C23.6517 66.6056 27.7254 65.4564 32.4498 63.3963C32.4923 63.3823 32.5347 63.3543 32.5771 63.3403L31.5446 61.0139Z"
          fill="#1C2535"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.9285 50.4121L38.4574 53.8464L21.2869 61.3445L19.7494 57.8855L36.9285 50.4121Z"
          fill="#2C374B"
        />
        <path
          opacity="0.72"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M40.4255 60.174L191.819 255.523C181.421 249.518 150.227 246.052 98.2374 245.127C46.2482 244.201 17.5024 247.93 12 256.313L23.7252 66.6185L40.4255 60.174Z"
          fill="#0F1624"
        />
      </g>
      <defs>
        <clipPath id="clip0_2543_6635">
          <rect
            width="192"
            height="267"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
    <svg
      v-else
      class="w-48 h-64"
      width="192"
      height="267"
      viewBox="0 0 192 267"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2478_4055)">
        <path
          d="M192 255.756C192 261.485 157.515 266.192 112.997 266.873C109.392 266.933 105.717 266.953 102 266.953C100.91 266.953 99.8342 266.953 98.7722 266.953C96.7601 266.953 94.762 266.933 92.7778 266.913C87.7895 266.853 82.9269 266.733 78.218 266.572C40.0578 265.27 12 260.924 12 255.776C12 249.587 52.2981 244.58 102 244.58C151.702 244.58 192 249.567 192 255.756Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.14 37.9919L34.8969 45.3634L40.4841 57.9623L31.7992 61.7461L22.1241 39.9399L29.14 37.9919Z"
          fill="#B0B0B0"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.9762 1.52782L29.1525 37.9909L22.5353 40.8726L6.35894 4.40952L12.9762 1.52782Z"
          fill="#CACACA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.1643 6.45965L15.8075 7.90945L9.19027 10.7912L8.54709 9.34136L15.1643 6.45965Z"
          fill="#B0B0B0"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.1833 43.6369L17.7676 52.8163L23.3689 65.4151L32.068 61.6313L22.3929 39.825L16.1833 43.6369Z"
          fill="#B0B0B0"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.61742 4.29428L22.7938 40.7574L16.1765 43.6391L0.000177202 7.17599L6.61742 4.29428Z"
          fill="#CACACA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.8057 9.22194L9.44888 10.6717L2.83163 13.5534L2.18845 12.1036L8.8057 9.22194Z"
          fill="#B0B0B0"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.577 62.7927C37.2448 60.7466 40.7669 58.5884 40.484 57.9577C40.2011 57.3131 36.2122 58.4342 31.5444 60.4663L32.577 62.7927Z"
          fill="#CACACA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.5447 60.4668C31.5022 60.4808 31.4598 60.5088 31.4174 60.5228C26.693 62.5829 23.0861 64.7692 23.369 65.4138C23.6519 66.0585 27.7256 64.9093 32.4499 62.8492C32.4924 62.8352 32.5348 62.8072 32.5773 62.7932L31.5447 60.4668Z"
          fill="#CACACA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.9286 49.8623L38.4575 53.2966L21.287 60.7947L19.7495 57.3357L36.9286 49.8623Z"
          fill="#E2E2E2"
        />
        <path
          opacity="0.717113"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M40.4255 59.6285L191.819 254.977C181.421 248.972 150.227 245.507 98.2374 244.581C46.2482 243.656 17.5024 247.385 12 255.768L23.7252 66.073L40.4255 59.6285Z"
          fill="#E9E9E9"
        />
      </g>
      <defs>
        <clipPath id="clip0_2478_4055">
          <rect
            width="192"
            height="267"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>

    <p class="font-medium text-neutral-light-800 dark:text-neutral-dark-200">
      {{ t("player.empty_video") }}
    </p>
  </div>
</template>
