import type { IPixellotPlayerSourceType } from "~/modules/pixellot-sdk";

interface IResponseVideoTimestamps {
  timestamps?: {
    pts: number;
    ts: number;
  }[];
}

const toSeconds = (timeInMs: number) => timeInMs / 1000;

/**
 * Retrieves the actual event time for the specified stream based on the provided timestamp and current video time.
 *
 * @param eventId - The identifier of the event.
 * @param streamType - The desired stream type ("hd" or "pano").
 * @param currentTime - The current time of the switched video in milliseconds.
 * @returns Promise<number> - The actual event time for the chosen stream in seconds.
 * 
 * Timestamps service git link:
 * @link https://github.com/Pixellot/video-timestamps-api
 */
export function getActualEventTimeForStream(
  eventAtlitId: string,
  options: {
    streamType: IPixellotPlayerSourceType;
    timeInSeconds: number;
    eventAbeId?: string;
  },
): Promise<number> {
  const { streamType, timeInSeconds, eventAbeId } = options;
  const eventId = eventAbeId || eventAtlitId;
  const baseURL = eventAbeId ? useRuntimeConfig().public.apiBaseTimestampURL : useRuntimeConfig().public.apiBaseURL;
  const timeInMilliseconds = Math.floor((timeInSeconds || 0) * 1000); // TODO: Manage fallback
  const params = `events/${eventId}/videoTimestamps/${streamType}/vod/manyTsFromPts?pts=${timeInMilliseconds}`;
  const URL = eventAbeId ? `/v1/${params}` : `/api/v1/video-timestamps/${params}`;

  return $fetch<IResponseVideoTimestamps>(URL, {
    baseURL,
    // For B2B timestamp service we need to hardcode auth API key which is the same for all envs
    // For B2C/Atlit the timestamp service is public and does not require auth headers
    headers: eventAbeId ? { "x-api-key": "QhnbiID14A9OAt03GKupV1kK8fT3TuAo9ZkfuYPH" } : undefined,
  })
    .then((res) => {
      if (res.timestamps?.length) {
        return toSeconds(res.timestamps[0].pts);
      }

      console.warn(
        `No timestamp found for event "${eventId}" and stream "${streamType}" with current time ${timeInMilliseconds}. Falling back to provided time.`,
      );
      return toSeconds(timeInMilliseconds);
    })
    .catch((error) => {
      console.warn(
        `Failed to get timestamp for event "${eventId}" and stream "${streamType}". Falling back to provided time.`,
        error,
      );
      return toSeconds(timeInMilliseconds);
    });
}
