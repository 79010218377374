<script lang="ts" setup>
const props = defineProps<{
  errorMessage?: string;
}>();

const { t } = useI18n();
const colorMode = useColorMode();
const isDark = computed(() => colorMode.value === "dark");
</script>

<template>
  <div
    class="flex flex-col gap-6 aspect-video justify-center items-center bg-neutral-light-100 dark:bg-neutral-dark-800 p-10"
  >
    <svg
      v-if="isDark"
      class="w-48 h-28"
      width="197"
      height="115"
      viewBox="0 0 197 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2543_6667)">
        <path
          d="M188.728 27.1061L196.115 27.855L196.942 19.8304C197.561 13.8294 193.14 8.44553 187.089 7.83213L177.45 6.85498L188.728 27.1061Z"
          fill="#1C2535"
        />
        <path
          d="M178.954 26.355L168.128 6.98595L151.885 5.35498L162.711 24.724L178.954 26.355Z"
          fill="#1C2535"
        />
        <path
          d="M129.328 21.855L118.303 2.7058L114.704 2.35498C111.764 7.35498 110.294 9.85498 110.294 9.85498C110.294 9.85498 111.125 13.3175 112.786 20.2425L129.328 21.855Z"
          fill="#1C2535"
        />
        <path
          d="M112.052 27.855L105.267 36.3021L110.294 48.5469L100.979 65.5001L107.038 80.9981L98.5 95.355L103.763 107.412L176.664 114.799C182.523 115.394 187.78 111.148 188.379 105.339L195.496 36.3021L112.052 27.855Z"
          fill="#2C374B"
        />
        <path
          d="M154.893 23.355L144.027 3.98542L127.824 2.35498L138.624 21.7177L154.893 23.355Z"
          fill="#1C2535"
        />
        <path
          d="M73.687 21.3088L58.5508 3.85498L42.1069 5.90109L57.2433 23.355L73.687 21.3088Z"
          fill="#1C2535"
        />
        <path
          d="M48.1221 24.3088L32.9858 6.85498L16.542 8.90107L31.6783 26.355L48.1221 24.3088Z"
          fill="#1C2535"
        />
        <path
          d="M22.5573 26.7657L7.7468 9.85498C2.75974 11.2369 -0.563541 16.1752 0.0794505 21.5295L1.0192 29.355L22.549 26.6971L22.5573 26.7657Z"
          fill="#1C2535"
        />
        <path
          d="M81.2061 18.855L76.4518 8.32543L78.9572 0.85498L66.1679 2.37227L81.2061 18.855Z"
          fill="#1C2535"
        />
        <path
          d="M1.50381 36.1805L9.92406 105.485C10.6326 111.317 15.946 115.487 21.7654 114.776L91.7328 106.239L84.0363 95.355L89.7502 80.6927L79.8668 65.5001L85.9266 49.2538L76.4518 36.1805L82.0272 26.355L1.50381 36.1805Z"
          fill="#2C374B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M79.7343 40.7689L85.9266 49.2538L79.7343 65.0142L89.7502 80.6927L84.9591 92.9198L65.6192 95.2994C63.621 95.5419 61.6097 94.9845 60.0278 93.7505C58.446 92.5165 57.4232 90.7069 57.1846 88.7191L52.6871 51.257C52.4485 49.2693 53.0138 47.2672 54.2581 45.6908C55.5025 44.1146 57.3244 43.0934 59.3228 42.8522L79.7343 40.7689ZM89.4118 81.409L71.7562 73.9709C71.3651 73.7752 70.9186 73.7203 70.491 73.8152C70.0641 73.91 69.6824 74.1484 69.4113 74.4911L60.5044 84.5167L60.953 88.2626C61.0722 89.2562 61.5836 90.161 62.3744 90.778C63.1654 91.395 64.1709 91.674 65.1699 91.5535L86.433 89.0522L89.4118 81.409ZM70.0871 52.9519C68.5892 53.1333 67.2228 53.8992 66.2902 55.0814C65.3571 56.2637 64.9336 57.7652 65.1125 59.2552C65.2914 60.7456 66.0581 62.1029 67.2444 63.0287C68.43 63.9544 69.938 64.3723 71.4366 64.1909C72.9351 64.0095 74.3008 63.2436 75.2342 62.0613C76.1669 60.879 76.5904 59.3776 76.4117 57.8871C76.233 56.3971 75.4655 55.0398 74.2799 54.1141C73.0936 53.1884 71.5856 52.7705 70.0871 52.9519Z"
          fill="#6E7C96"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M131.394 41.8383C133.433 42.0434 135.307 43.045 136.604 44.6224C137.901 46.2002 138.516 48.2248 138.313 50.2511L134.477 88.4493C134.274 90.4758 133.269 92.3377 131.683 93.6249C130.098 94.9127 128.063 95.5212 126.024 95.3158L100.087 92.6427L102.098 89.1293L105.071 75.574L100.979 65.5001L110.294 48.5469L107.202 40.7689L131.394 41.8383ZM118.213 65.7974C117.871 65.5821 117.469 65.4791 117.064 65.5036C116.66 65.5277 116.273 65.678 115.96 65.9332L105.071 75.574L102.091 89.1628L126.408 91.4964C127.428 91.5997 128.447 91.2958 129.24 90.6516C130.034 90.0073 130.537 89.0757 130.638 88.0614L131.981 74.6921L118.213 65.7974Z"
          fill="#6E7C96"
        />
      </g>
      <defs>
        <clipPath id="clip0_2543_6667">
          <rect
            width="197"
            height="114"
            fill="white"
            transform="translate(0 0.854965)"
          />
        </clipPath>
      </defs>
    </svg>
    <svg
      v-else
      class="w-48 h-28"
      width="197"
      height="114"
      viewBox="0 0 197 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4308_120186)">
        <path
          d="M188.499 26.2511L195.868 27L196.692 18.9754C197.31 12.9744 192.899 7.59055 186.864 6.97715L177.25 6L188.499 26.2511Z"
          fill="#CACACA"
        />
        <path
          d="M178.75 25.5L167.952 6.13097L151.75 4.5L162.548 23.869L178.75 25.5Z"
          fill="#CACACA"
        />
        <path
          d="M129.25 21L118.252 1.85082L114.663 1.5C111.73 6.5 110.264 9 110.264 9C110.264 9 111.093 12.4625 112.75 19.3875L129.25 21Z"
          fill="#CACACA"
        />
        <path
          d="M112.018 27L105.25 35.4472L110.264 47.6919L100.973 64.6451L107.017 80.1431L98.5 94.5L103.75 106.557L176.465 113.945C182.31 114.539 187.553 110.293 188.151 104.484L195.25 35.4472L112.018 27Z"
          fill="#E9E9E9"
        />
        <path
          d="M154.75 22.5L143.911 3.13044L127.75 1.5L138.522 20.8627L154.75 22.5Z"
          fill="#CACACA"
        />
        <path
          d="M73.75 20.4539L58.6522 3L42.25 5.04611L57.348 22.5L73.75 20.4539Z"
          fill="#CACACA"
        />
        <path
          d="M48.25 23.4539L33.152 6L16.75 8.04609L31.8479 25.5L48.25 23.4539Z"
          fill="#CACACA"
        />
        <path
          d="M22.75 25.9108L7.97714 9C3.00273 10.3819 -0.31211 15.3202 0.329249 20.6745L1.26662 28.5L22.7418 25.8422L22.75 25.9108Z"
          fill="#CACACA"
        />
        <path
          d="M81.25 18L76.5077 7.47045L79.0068 0L66.25 1.51729L81.25 18Z"
          fill="#CACACA"
        />
        <path
          d="M1.75 35.3255L10.1489 104.63C10.8556 110.462 16.1556 114.632 21.9601 113.921L91.75 105.384L84.073 94.5L89.7724 79.8377L79.9141 64.6451L85.9585 48.3988L76.5077 35.3255L82.0691 25.5L1.75 35.3255Z"
          fill="#E9E9E9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M79.7819 39.9141L85.9585 48.399L79.7819 64.1593L89.7724 79.8379L84.9935 92.0649L65.7026 94.4446C63.7095 94.687 61.7033 94.1296 60.1255 92.8957C58.5477 91.6617 57.5274 89.852 57.2894 87.8643L52.8034 50.4021C52.5654 48.4145 53.1292 46.4123 54.3704 44.836C55.6117 43.2597 57.4289 42.2386 59.4222 41.9974L79.7819 39.9141ZM89.4349 80.5542L71.8241 73.1161C71.4339 72.9204 70.9886 72.8655 70.5621 72.9603C70.1363 73.0551 69.7556 73.2935 69.4851 73.6362L60.6009 83.6618L61.0483 87.4078C61.1672 88.4013 61.6773 89.3062 62.4661 89.9231C63.2551 90.5401 64.2581 90.8192 65.2545 90.6986L86.4636 88.1973L89.4349 80.5542ZM70.1592 52.0971C68.6651 52.2784 67.3022 53.0443 66.372 54.2266C65.4412 55.4089 65.0188 56.9103 65.1972 58.4004C65.3757 59.8908 66.1404 61.2481 67.3238 62.1738C68.5064 63.0995 70.0105 63.5175 71.5053 63.3361C73 63.1546 74.3622 62.3887 75.2932 61.2065C76.2236 60.0242 76.646 58.5228 76.4677 57.0323C76.2895 55.5422 75.524 54.185 74.3413 53.2592C73.1581 52.3335 71.6539 51.9156 70.1592 52.0971Z"
          fill="#CACACA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M131.311 40.9835C133.345 41.1885 135.214 42.1902 136.508 43.7676C137.802 45.3453 138.415 47.3699 138.212 49.3962L134.386 87.5945C134.183 89.621 133.18 91.4829 131.599 92.77C130.018 94.0579 127.988 94.6663 125.955 94.461L100.083 91.7878L102.089 88.2745L105.055 74.7192L100.973 64.6452L110.264 47.692L107.18 39.9141L131.311 40.9835ZM118.164 64.9426C117.822 64.7272 117.421 64.6242 117.017 64.6488C116.614 64.6729 116.228 64.8232 115.916 65.0784L105.055 74.7192L102.082 88.308L126.338 90.6415C127.355 90.7449 128.371 90.441 129.162 89.7967C129.954 89.1524 130.456 88.2208 130.556 87.2065L131.896 73.8372L118.164 64.9426Z"
          fill="#CACACA"
        />
      </g>
      <defs>
        <clipPath id="clip0_4308_120186">
          <rect
            width="197"
            height="114"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>

    <p class="font-medium text-neutral-light-800 dark:text-neutral-dark-200">
      {{ props.errorMessage || t("player.playback_error") }}
    </p>
  </div>
</template>
