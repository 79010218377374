<script lang="ts" setup>
import type { ITag } from "~/types";
import { formatTime } from "~/helpers/format-time";

const props = defineProps<{
  tags?: ITag[];
}>();
const emit = defineEmits<{
  (event: "delete-tag", tag: ITag): void;
  (event: "edit-tag", tag: ITag): void;
}>();
</script>

<template>
  <div
    class="relative flex flex-col gap-3 p-3 bg-white dark:bg-neutral-dark-700 rounded text-left max-h-32 sm:max-h-48 lg:max-h-96 overflow-auto z-40"
  >
    <div
      v-for="tag in props.tags"
      :key="tag.id"
    >
      <div class="flex items-center justify-between gap-2">
        <div class="text-black dark:text-white text-xs font-bold">
          {{ tag.title }}
        </div>
        <div class="flex gap-2">
          <UButton
            icon="edit"
            variant="link-secondary"
            @click.stop="emit('edit-tag', tag)"
          />
          <UButton
            icon="delete"
            variant="link-danger"
            @click.stop="emit('delete-tag', tag)"
          />
        </div>
      </div>
      <div class="flex items-center justify-between gap-2 mt-2 text-xs font-medium">
        <div class="text-neutral-light-800 dark:text-neutral-dark-200">
          {{ formatTime(tag.startTime) }} - {{ formatTime(tag.endTime) }}
        </div>
        <div
          v-if="tag.playerNumber"
          class="text-white bg-[#233C5F] rounded-full h-4 w-4 overflow-hidden text-center py-0.5 leading-[15px]"
        >
          {{ tag.playerNumber }}
        </div>
      </div>
    </div>
  </div>
</template>
